import React from 'react'
import {Slide} from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/1.jpg'
import sImg2 from '../../images/event/2.jpg'
import sImg3 from '../../images/event/3.jpg'
import sImg4 from '../../images/event/4.jpg'
import sImg5 from '../../images/event/5.jpg'

const Events = [
    {
        Simg: sImg1,
        title: 'Cérémonie civile',
        li1: '11h00',
        li2: 'Mairie de Nouméa',
    },
    {
        Simg: sImg2,
        title: 'Cérémonie laïque',
        li1: '16h30',
        li2: 'Ilot Maître',
    },
    {
        Simg: sImg3,
        title: 'Vin d\'honneur',
        li1: '19h00',
        li2: 'Ilot Maître',
    },
    {
        Simg: sImg4,
        title: 'Réception',
        li1: '20h00',
        li2: 'Ilot Maître',
    },
    {
        Simg: sImg5,
        title: 'Repos bien mérité',
        li1: 'Le lendemain',
        li2: 'Ilot Maître',
    },
]

const EventSection = (props) => {
    return (
        <section className="wpo-event-section section-padding" id="event">
            <div className="container">
                <SectionTitle MainTitle={'Festivités'}/>
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-2 col-md-2 col-12 mx-auto text-center" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt=""/>
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li><i>{event.li2}</i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;