import React, {Fragment} from 'react';
import Hero2 from '../../components/hero2/hero2';
import CoupleSection2 from '../../components/CoupleSection2/CoupleSection2';
import RsvpSection from '../../components/RsvpSection/RsvpSection';
import EventSection from '../../components/EventSection/EventSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'


const HomePage = () => {

    return (
        <Fragment>
            <Hero2/>
            <EventSection/>
            <CoupleSection2/>
            <RsvpSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

export default HomePage;